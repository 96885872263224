import { NextPage } from "next"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import withPrivateRoute from "../withPrivateRoute"
import { AuthContext } from "../utils"
import { AccessErrorPage, ServiceProvisionRecordComponent } from "../components"
import { ProgramRecordPageComponent } from "./program-record"
import { ContactFromParentPageComponent } from "./contact-facility"

const ServiceProvisionRecordPage: NextPage = () => {
  const { t } = useTranslation()
  const { childInformation } = useContext(AuthContext)
  if (
    childInformation &&
    childInformation?.my_page_setting?.service_provision_record
  ) {
    return <ServiceProvisionRecordComponent />
  } else if (
    childInformation &&
    childInformation?.my_page_setting?.contact_facility
  ) {
    return <ContactFromParentPageComponent />
  } else if (
    childInformation &&
    childInformation?.my_page_setting?.activity_record
  ) {
    return <ProgramRecordPageComponent />
  }
  return <AccessErrorPage title={t("Thank you for your time")} />
}
export default withPrivateRoute(ServiceProvisionRecordPage, {
  title: "マイページトップ",
  route: `program-record`,
})
