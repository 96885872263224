import { MessageComponent, theme } from "@project/shared"
import moment from "moment"
import { NextPage } from "next"
import Head from "next/head"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery, useQueryClient } from "react-query"
import styled from "styled-components"
import { AccessErrorPage, SendMessageComponent } from "../../components"
import {
  deleteComment,
  fetchChildChat,
  getSelfChildDetail,
} from "../../services"
import { Divisor, FormTitle } from "../../utils/shared-style"
import withPrivateRoute from "../../withPrivateRoute"

// types
import { MessagesResponseProps } from "@project/shared/src/components/molecules/MessageComponent/types"
import { AuthContext } from "../../utils"

const DataContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  margin-top: 41px;
  padding: 11px 30px 27px 30px;
  table th {
    word-break: break-all;
    white-space: pre-wrap;
  }
  .tag {
    display: flex;
    border: none;
    border-radius: 5px;
    height: 35px;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`
const Info = styled.div`
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  word-break: break-all;
  white-space: pre-wrap;

  .keep-all {
    word-break: keep-all;
  }

  @media (max-width: ${theme.breakpoints.md}) {
    .liner-breaker {
      display: none;
    }
  }
`

export const ContactFromParentPageComponent = () => {
  const QueryClient = useQueryClient()
  const { t } = useTranslation()
  const currentLang = localStorage.getItem("i18nextLng") as "ja" | "en"

  const { data: childData, isLoading: childLoading } = useQuery<any, Error>(
    ["fetch-self-child"],
    getSelfChildDetail,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 1,
      select: ({ data }) => ({
        childId: data?.id,
        facilityName:
          data?.active_contract_facility?.facility?.facility_name_short,
      }),
    }
  )

  const {
    isLoading,
    data: initialData,
    refetch,
  } = useQuery(
    ["chat-data", 1, { childId: childData?.childId }],
    fetchChildChat,
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 1,
      cacheTime: 0,
      enabled: !!childData?.childId,
      select: (response): MessagesResponseProps => {
        return {
          data: response?.data?.map((data) => {
            return {
              key: data.id,
              id: data.id,
              date: moment(data?.created_datetime).isValid
                ? moment(data?.created_datetime).format("YYYY年MM月DD日 HH:mm")
                : moment(data?.date).format("YYYY年MM月DD日 HH:mm"),
              message:
                // IF staff id is present means the message is not sent from the child dashboard.
                !data.staff?.id ? data.parent_comment : data.comment,
              self: !data.staff?.id ? true : false,
              file_url: data?.file_url,
              file_name: data?.file_name,
              type: data?.type,
              read: !!data?.already_read_flag,
              staff_name: data?.staff?.staff_name,
              parent_name: data?.child?.Parent?.parent_name,
            }
          }),
          count: response?.count,
        }
      },
    }
  )

  const { mutate: deleteComments, isLoading: isDeleting } = useMutation(
    ["chat-data"],
    (cmt_id) => deleteComment(cmt_id),

    /**
     * Here Optimistic update is used to update the UI before the mutation is completed.
     * This is done to give a better user experience also it makes process more snappy.
     */
    {
      onMutate: async (cmt_id: number) => {
        await QueryClient.cancelQueries(["chat-data"])
        const previousCommentsData =
          QueryClient.getQueryData<MessagesResponseProps>(["chat-data"])
        QueryClient.setQueriesData<MessagesResponseProps>(
          ["chat-data"],
          (old) => {
            const newComments = old?.data.filter((item) => item.id !== cmt_id)
            return {
              data: newComments,
              count: old?.count - 1,
            }
          }
        )
        return { previousCommentsData }
      },

      onError: (_err, _newComment, context: any) => {
        QueryClient.setQueriesData<MessagesResponseProps>(
          ["chat-data"],
          context.previousCommentsData
        )
      },

      onSettled: () => {
        QueryClient.invalidateQueries(["chat-data"])
      },
    }
  )

  return (
    <>
      <Head>
        <title>{t("Contact facility")}</title>
      </Head>

      <DataContainer>
        <FormTitle>
          <img
            style={{
              marginRight: "10px",
            }}
            src={"/assets/icons/title_icon.svg"}
          />
          <span
            style={{
              wordBreak: "break-all",
            }}
          >{`${childData?.facilityName || ""}`}</span>
        </FormTitle>
        <Divisor />
        <Info>
          {currentLang === "en" ? (
            "You can enter contact information for facilities and instructors, such as how your child is at home today and explanations of medicines. "
          ) : (
            <>
              {"今日のお子さまの様子やお薬の説"}
              <span className={"keep-all"}>{"明など、"}</span>
              {"施設や指導員の連絡先を入力でき"}
              <span className={"keep-all"}>{"ます。"}</span>
            </>
          )}

          <br className={"liner-breaker"} />
          {/* {t("Please fill out the form below and press the 'Send' button.")} */}
          {currentLang === "en" ? (
            "Please fill out the form below and press the 'Send' button."
          ) : (
            <>
              {"下記のフォームにご記入"}
              <span className={"keep-all"}>{"の上、"}</span>
              <span className={"keep-all"}>{"「送信」"}</span>
              {"ボタンを押してください"}
            </>
          )}
        </Info>
        <Divisor />
        <MessageComponent
          data={initialData?.data}
          loading={isLoading || childLoading}
          deleteComments={deleteComments}
          isDeleting={isDeleting}
        />
        <SendMessageComponent data={initialData} refetch={refetch} />
      </DataContainer>
    </>
  )
}
const ContactFromParentPage: NextPage = () => {
  const { t } = useTranslation()
  const { childInformation } = useContext(AuthContext)
  if (
    childInformation &&
    !childInformation?.my_page_setting?.contact_facility
  ) {
    return <AccessErrorPage title={t("Thank you for your time")} />
  }
  return <ContactFromParentPageComponent />
}

export default withPrivateRoute(ContactFromParentPage, {
  title: "マイページトップ",
})
